import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from './createAppIntegration.module.scss'
import { appIntFormSteps } from '../../data/AppIntegrationsList.constants'
import { IAppIntegrationModel } from '../../../../../interfaces/interfaces'
import LineProgressBar from '../../../../../Components/molecules/progress/LineProgressBar/LineProgressBar'
import {
    ActionStatusTypes,
    brandColors,
    brandImages,
    gaColors,
    gaImages,
} from '../../../../../assets/globalConstants'
import GaImage from '../../../../../interfaces/Image/Image'
import HelpIcon from '../../../../../assets/iconsLibrary/help/helpIcon'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'
import cx from 'classnames'
import { history } from '../../../../../services/history'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import ChevronLeftIcon from '../../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon'
import DropdownMenu from '../../../../../Components/DropdownMenu/DropdownMenu'
import { onPremise } from '../../../../../data/globalVar'
import {
    helpMenuExtraMobile,
    helpMenuExtraOnPremiseMobile,
} from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import CloseConfirmation from '../../../Home/HomeComponents/ageVerification/modal/Status/CloseConfirmation/CloseConfirmation'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'
import { clearModalState } from '../../../../../store/appIntegrationModal/appIntegrationModalActions'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import { createAppIntegration } from '../../../../../store/appIntegrations/appIntegrationsActions'
import { sendingAppIntegrationDataStatus } from '../../../../../store/appIntegrationModal/appIntegrationModalSelectors'
import { showNotification } from '../../../../common/Notification/notificationActions'
import { getOrgAction } from '../../../../common/organization/organizationActions'
import { sessionSelectors } from '../../../../common/session'
import { orgSelectors } from '../../../../common/organization'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { organizationScopes } from '../../../../common/organization/views/general/organization.constants'

type ICreateAppIntegrationProps = {}

const CreateAppIntegration: React.FC<ICreateAppIntegrationProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Selectors
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const sendingAppStatus = useSelector(sendingAppIntegrationDataStatus)
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const orgName = useSelector(orgSelectors.getOrg)?.orgInfo?.name
    const orgIsLoading = useSelector(orgSelectors.getOrgIsLoading)

    // Consts
    const initialFormState: IAppIntegrationModel = {
        client_name: '',
        redirect_uris: [''],
        post_logout_redirect_uris: [''],
        grant_types: [
            'client_credentials',
            'authorization_code',
            'refresh_token',
            'implicit',
        ],
        response_types: ['id_token', 'code', 'token'],
        scope: 'openid',
        formScopes: [],
        audience: null,
        owner: '',
        policy_uri: '',
        allowed_cors_origins: [],
        tos_uri: '',
        contacts: [],
        client_secret_expires_at: 0,
        subject_type: 'public',
        token_endpoint_auth_method: 'client_secret_basic',
        request_uris: [],
        userinfo_signed_response_alg: 'none',
        metadata: null,
        display_tutorial: true,
    }

    const canReadOrg = !!(
        availableScopes &&
        userHasScopes(organizationScopes.read, availableScopes)
    )

    // States
    const [formData, setFormState] = useState(initialFormState)
    const [companyLogoChecked, setCompanyLogoChecked] = useState(true)

    const [currentStep, setCurrentStep] = useState(appIntFormSteps[0])
    const [showSkipConfirmation, setShowSkipConfirmation] = useState(false)
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
    const {
        client_name,
        formScopes,
        redirect_uris,
        policy_uri,
        tos_uri,
        logo_uri,
        display_tutorial,
        post_logout_redirect_uris,
    } = formData

    const firstFormModified =
        client_name?.trim()?.length ||
        (formScopes?.length && formScopes[0]?.trim()?.length) ||
        (redirect_uris?.length && redirect_uris[0]?.trim()?.length) ||
        policy_uri?.trim()?.length ||
        tos_uri?.trim()?.length

    const secondFormModified = logo_uri?.trim()?.length || !display_tutorial

    const Component = currentStep?.component

    // Effects
    useEffect(() => {
        dispatch(clearModalState())
        if (orgId && canReadOrg && !orgName && !orgIsLoading) {
            // Just in cloud for now
            !onPremise && dispatch(getOrgAction())
        }
    }, [])

    useEffect(() => {
        if (sendingAppStatus === ActionStatusTypes.success) {
            setCurrentStep(appIntFormSteps[currentStep?.id])
        } else if (sendingAppStatus === ActionStatusTypes.failed) {
            navigateToAppList()
            setCurrentStep(appIntFormSteps[0])
        }
    }, [sendingAppStatus])

    // functions
    const helpMenuExtraMobileList = !onPremise
        ? helpMenuExtraMobile
        : helpMenuExtraOnPremiseMobile

    const setTutorialChecked = (checked: boolean) =>
        setFormState({
            ...formData,
            display_tutorial: checked,
        })

    const forwardFunction = () => {
        const stepIsOne = currentStep?.order === 0
        const stepIsThree = currentStep?.order === 2
        stepIsThree
            ? (navigateToAppList(),
              dispatch(
                  showNotification(
                      'appIntegrations.createAppIntSuccess',
                      'success',
                      ''
                  )
              ),
              dispatch(clearModalState()))
            : stepIsOne
            ? setCurrentStep(appIntFormSteps[currentStep?.id])
            : secondFormModified
            ? createAppInt()
            : setShowSkipConfirmation(true)
    }

    const setRequirementsStepModified = () => {}

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleScopeChange = (scopes) => {
        setFormState({ ...formData, formScopes: scopes })
    }

    const handleInputURLChange = (event, index, name) => {
        const target = event.target
        const value = target.value
        const formDataURLValue = formData[name]
        formDataURLValue[index] = value?.trim()

        setFormState({
            ...formData,
            [name]: formDataURLValue,
        })
    }

    const removeUrl = (index, name) => {
        const modifiedRedirectUris = formData[name]
        modifiedRedirectUris?.splice(index, 1)
        const redirectUrisWithoutLastItem = modifiedRedirectUris

        setFormState({
            ...formData,
            [name]: redirectUrisWithoutLastItem,
        })
    }

    const createAppInt = () => {
        const formattedData = formatDataToSend()
        dispatch(showScreenLoaderAction())
        dispatch(createAppIntegration(formattedData))
    }

    const formatDataToSend = () => {
        const formattedData = {
            ...formData,
            scope: formScopes?.length
                ? `openid ${formScopes?.join(' ')}`
                : 'openid',
            logo_uri: companyLogoChecked ? formData?.logo_uri : undefined,
        }

        if (
            post_logout_redirect_uris?.length &&
            !post_logout_redirect_uris[0]?.length
        ) {
            delete formattedData?.post_logout_redirect_uris
        }

        delete formattedData.formScopes

        return formattedData
    }

    const navigateToAppList = () => {
        history.push('/app-integrations')
        dispatch(setSelectedRoute('app-integrations'))
        setCurrentStep(appIntFormSteps[0])
    }

    return (
        <>
            <header
                id={'topOfTheWiew'}
                aria-label="Studio"
                className={styles.topbarStepper}
            >
                <div className={styles.logoCont}>
                    <GaImage image={brandImages.logoPlatform} />
                </div>

                <LineProgressBar
                    className={styles.progressBar}
                    activeStep={currentStep}
                    formSteps={appIntFormSteps}
                    notClickableSteps={true}
                    onHandleChange={() => {}}
                />

                <div className={styles.rightSpace}>
                    <DropdownMenu
                        menuOptions={helpMenuExtraMobileList()}
                        icon={gaImages.helpCircle}
                        iconComponent={() => (
                            <HelpIcon
                                className={cx(
                                    'marginRight16',
                                    styles.rightSpace__disabledIcon
                                )}
                                size={24}
                                color={brandColors.neutral1000}
                            />
                        )}
                        iconClassName={styles.user__image}
                    ></DropdownMenu>

                    <svg
                        className={cx('marginLeft4 marginRight4')}
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="36"
                        viewBox="0 0 2 36"
                        fill="none"
                    >
                        <path d="M1 0V36" stroke="#C6C6C8" />
                    </svg>
                    <div
                        className={styles.rightSpace__close}
                        onClick={() => {
                            currentStep?.id !== 3 && firstFormModified
                                ? setShowCloseConfirmation(true)
                                : navigateToAppList()
                        }}
                    >
                        <CloseIcon size={24} color={gaColors.neutral1000} />
                    </div>
                </div>
            </header>
            {currentStep?.order !== 2 ? (
                <div className={styles.backLink}>
                    <span
                        onClick={() => {
                            currentStep?.order === 0
                                ? firstFormModified
                                    ? setShowCloseConfirmation(true)
                                    : navigateToAppList()
                                : setCurrentStep(
                                      appIntFormSteps[currentStep?.order - 1]
                                  )
                        }}
                    >
                        <ChevronLeftIcon size={24} /> {t('public.back')}
                    </span>
                </div>
            ) : null}
            <div className={styles.createAppInt}>
                {Component ? (
                    <Component
                        buttonFunction={forwardFunction}
                        activeStep={currentStep || appIntFormSteps[0]}
                        handleInputChange={handleInputChange}
                        handleInputUrlChange={handleInputURLChange}
                        removeUrl={removeUrl}
                        handleScopeChange={handleScopeChange}
                        formData={formData}
                        setRequirementsStepModified={
                            setRequirementsStepModified
                        }
                        setTutorialChecked={setTutorialChecked}
                        setCompanyLogoChecked={setCompanyLogoChecked}
                        companyLogoChecked={companyLogoChecked}
                        appIntFormSteps={appIntFormSteps}
                        secondFormModified={secondFormModified}
                    />
                ) : null}
            </div>
            {showCloseConfirmation ? (
                <CloseConfirmation
                    closeModal={() => setShowCloseConfirmation(false)}
                    continueFunction={() => {
                        setShowCloseConfirmation(false), navigateToAppList()
                    }}
                    goBackFunction={() => setShowCloseConfirmation(false)}
                />
            ) : null}
            {showSkipConfirmation ? (
                <ConfirmationModal
                    confirmFunction={() => {
                        setShowSkipConfirmation(false), createAppInt()
                    }}
                    hideModal={() => setShowSkipConfirmation(false)}
                    title="appIntegrations.verificationPage.sureSkipTitle"
                    description="appIntegrations.verificationPage.sureSkipText"
                    continueText="appIntegrations.verificationPage.yesSkip"
                    image={gaImages.yellowWarningPng}
                    show={true}
                    warning
                />
            ) : null}
        </>
    )
}

export default CreateAppIntegration
